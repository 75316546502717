<template>
  <div>
    <v-img src="@/assets/images/about/image1.png">
      <v-container class="pa-0">
        <p class="white--text font60 mb-3 font-weight-bold bg-top">
          {{ $t("全球贸易平台！") }}
        </p>
        <p class="white--text font34 font-weight-bold">
          {{ $t("使用taoify建立您的在线业务在全球任何地方销售您的产品") }}
        </p>
      </v-container>
    </v-img>
    <v-container class="pa-0 shang mb-16">
      <v-row class="" align="center">
        <v-col cols="12" sm="6" md="9" class="">
          <v-card class="mr-md-16 px-13 py-8">
            <v-row align="center">
              <v-col cols="12" md="8">
                <div>
                  <p class="font32">{{ $t("中国·郑州·总部") }}</p>
                  <p class="font20 mb-3">{{ $t("售前手机") }}：15038350530</p>
                  <p class="font20 mb-0">
                    {{ $t("合作邮箱") }}：97668216@qq.com
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="4" class="d-flex justify-md-end">
                <v-card max-width="138" flat>
                  <v-img
                    max-width="138"
                    max-height="138"
                    src="@/assets/images/recruit/erweima.png"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="" md="3" class="d-flex justify-md-end">
          <v-card max-width="325" flat>
            <v-img
              max-width="325"
              src="@/assets/images/about/image2.png"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
      <p
        style="margin-top: 100px; color: #000"
        class="font32 text-center mb-11"
      >
        {{ $t("关于我们") }}
      </p>
      <v-row class="" align="center">
        <v-col cols="12" sm="6" md="5">
          <v-img
            class="rounded-2"
            max-width="480"
            src="@/assets/images/about/image3.png"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6" md="7">
          <p class="font20 mb-2 ml-6">
            {{ $t("郑州全搜索计算机技术服务有限公司") }}
          </p>
          <p class="text--secondary ml-6" style="margin-bottom: 82px">
            ——{{ $t("跨境行业全链路解决方案软件服务商") }}
          </p>
          <p class="ml-6">
            {{
              $t(
                "郑州全搜索计算机技术服务有限公司（www.zzqss.com）（简称郑州全搜索）是一家以互联网信息技术开发和物联网IOT开发和服务为核心，为企业提供现代的网络服务公司。"
              )
            }}
          </p>
          <p class="ml-6">
            {{
              $t(
                "郑州全搜索计算机技术服务有限公司依托自身完善的服务体系，强大的技术支持力量，以及丰富的经验、信息资源和市场运作实力，使公司得到迅猛发展，并拥有一支富有创造力、充满激情、团结向上的队伍。"
              )
            }}
          </p>
          <p class="mb-0 ml-6">
            {{
              $t(
                "郑州全搜索设立专门的产品研发团队，探索国际领先的互联网点上模式，扑捉未来市场的需求，将其转化为自己的产品，与互联网各领域内从业者与专家保持密切沟通，形成全搜索独特的市场敏锐和前瞻性，开发出多套国内领先国际上普遍使用的跨境电商系统。"
              )
            }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-card color="#F9FBFD" flat class="rounded-0">
      <v-container class="pa-0 pb-16">
        <p
          style="padding-top: 52px; color: #000"
          class="font32 text-center mb-8"
        >
          {{ $t("公司地址") }}
        </p>
        <v-img src="@/assets/images/about/image4.png">
          <v-row align="center" class="ml-6 ml-md-16 " style="width:100%;height:100%;">
            <v-card class="pa-9">
              <p class="font32 mb-0">{{ $t("联系我们") }}</p>
              <p class="mt-6 mb-1 text--disabled">{{ $t("公司电话") }}</p>
              <p class="mb-0">{{ $t("15038350530") }}</p>
              <p class="mt-6 mb-1 text--disabled">{{ $t("公司邮箱") }}</p>
              <p class="mb-0">{{ $t("97668216@qq.com") }}</p>
              <p class="mt-6 mb-1 text--disabled">{{ $t("公司地址") }}</p>
              <p class="mb-1">
                {{ $t("河南省郑州市金水区花园路正弘中心1417") }}
              </p>
              <p class="mb-1">{{ $t("公交站：花园路农科路") }}</p>
              <p class="mb-0">{{ $t("地铁站：花园路农科路") }}</p>
            </v-card>
          </v-row>
        </v-img>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "Home",
  data: () => ({}),
  methods: {},
};
</script>
<style lang="scss" scoped>
.bg-top {
  margin-top: 143px;
}
.shang {
  margin-top: -104px;
}
@media (max-width: 1300px) {
  .bg-top {
    margin-top: 63px;
  }
}
@media (max-width: 1000px) {
  .bg-top {
    margin-top: 53px;
  }
  .shang {
    margin-top: 14px;
  }
}
</style>